import React from 'react';

const TodosError = () => {
  return(
    <p className="TodoList-p TodoList--error">
      ¡Poyo! ¡Poyo! ¡Poyo! ¡Hubo un error!
    </p>
  )
};

export { TodosError };
